.avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ebeff5;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 2px 4px 0 rgba(50, 50, 93, 0.05), 0 4px 8px 0 rgba(136, 152, 170, 0.08);
}
.avatar-wrapper .avatar-dropdown-arrow {
  transition: opacity 0.1s linear, transform 0.15s linear;
}
.avatar-wrapper .avatar-dropdown-arrow.active {
  transform: rotate(180deg);
}
.avatar-wrapper:hover .avatar-dropdown-arrow {
  opacity: 0.2;
}
.avatar-photo {
  border-radius: 4px;
  margin-right: 0.5rem;
}
.dropdown-wrapper {
  position: absolute;
  transform: scale(0.8);
  transform-origin: top;
  transform-origin: right;
  right: 2%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  background-color: #fff;
  width: 245px;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 2px 4px 0 rgba(50, 50, 93, 0.05), 0 4px 8px 0 rgba(136, 152, 170, 0.08);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  opacity: 0;
  visibility: hidden;
}
.dropdown-wrapper.active {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 768px) {
  .dropdown-wrapper {
    width: 200px;
  }
}
.dropdown-profile-details {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.3rem;
  border-bottom: 1px solid #eaeaea;
}
.dropdown-name {
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 0;
  font-weight: 600;
}
.dropdown-email {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  color: #6f6f6f;
}
@media screen and (max-width: 768px) {
  .dropdown-name {
    font-size: 1rem;
  }
  .dropdown-email {
    font-size: 0.8rem;
  }
}
.dropdown-links {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.dropdown-links li {
  list-style: none;
  color: #525f7f;
  text-decoration: none;
  transition: all 0.1s linear;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  font-size: 1rem;
}
@media screen and (max-width: 768px) {
  .dropdown-links li {
    padding: 0.3rem 0.3rem;
    font-size: 0.8rem;
  }
}
.dropdown-links li:hover {
  background-color: #ebeff5;
}
.dropdown-links a:last-of-type {
  padding-bottom: 0;
}
