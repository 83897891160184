@media screen and (max-width: 480px) {
  div.table-responsive > table.table-top-users > thead > tr > th:nth-child(1) {
    min-width: 50px;
  }
  div.table-responsive > table.table-top-users > thead > tr > th:nth-child(2) {
    min-width: 200px;
  }
  div.table-responsive > table.table-top-users > thead > tr > th:nth-child(3) {
    min-width: 130px;
  }
  div.table-responsive > table.table-top-users > thead > tr > th:nth-child(4) {
    min-width: 130px;
  }
  div.table-responsive > table.table-top-users > thead > tr > th:nth-child(5) {
    min-width: 50px;
  }

  div.table-responsive
    > table.top-referrals-table
    > tbody
    > tr
    > td:nth-child(1) {
    min-width: 50px;
  }
  div.table-responsive
    > table.top-referrals-table
    > tbody
    > tr
    > td:nth-child(2) {
    min-width: 200px;
  }
  div.table-responsive
    > table.top-referrals-table
    > tbody
    > tr
    > td:nth-child(3) {
    min-width: 130px;
  }
  div.table-responsive
    > table.top-referrals-table
    > tbody
    > tr
    > td:nth-child(4) {
    min-width: 130px;
  }
  div.table-responsive
    > table.top-referrals-table
    > tbody
    > tr
    > td:nth-child(5) {
    min-width: 100px;
  }
  div.table-responsive
    > table.top-referrals-table
    > tbody
    > tr
    > td:nth-child(6) {
    min-width: 200px;
  }
}
